<template>
  <div>
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ $t(item.text) }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row>
      <v-col cols="12">
        <h2>{{ pageName }}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="no-padding">
        <v-tabs v-model="tab" class="tabs" background-color="#ffffff" dark>
          <v-tab v-can="'list-academic-class-subjects'" @click="routeTab(0)">{{
            $t("Classes")
          }}</v-tab>
          <v-tab v-can="'list-subjects-in-grade-year'" @click="routeTab(1)">{{
            $t("Subjects")
          }}</v-tab>
          <v-tab v-can="'list-academic-book-subjects'" @click="routeTab(2)">{{
            $t("Books")
          }}</v-tab>
          <v-tab @click="routeTab(3)">{{ $t("Admission Exam") }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-card>
      <v-tabs-items v-model="tab">
        <!-- Classes -->
        <v-tab-item v-can="'list-academic-class-subjects'">
          <v-card flat>
            <v-card-text>
              <DataTableComponent :table="table"></DataTableComponent>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Subjects -->
        <v-tab-item v-can="'list-subjects-in-grade-year'">
          <v-card flat>
            <v-card-text>
              <div class="card height-auto v-application v-application--is-ltr">
                <div class="card-body">
                  <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :items="subjects"
                    :items-per-page="15"
                    class="elevation-1 level1"
                    :loading="loading"
                    item-key="id"
                    loading-text="Loading... Please wait"
                    hide-default-footer
                  >
                    <template v-slot:top>
                      <v-row class="headerTable-div">
                        <v-col md="4" sm="12">
                          <h3 class="custom-header main-color">
                            {{ $t("Subjects") }}
                          </h3>
                        </v-col>
                        <v-col md="4" sm="12">
                          <label class="search" for="inpt_search">
                            <input
                              id="inpt_search"
                              @focus="inputFocus"
                              @blur="inputBlur"
                              type="text"
                              v-model="search"
                            />
                          </label>
                        </v-col>
                        <v-col md="4" sm="12" class="text-right">
                          <v-icon
                            v-if="assignSubjectsBtnVisible"
                            @click="addDialog = true"
                            :title="$t('Add New')"
                            large
                            class="main-color add-icon"
                            >add_circle</v-icon
                          >
                        </v-col>
                      </v-row>
                    </template>

                    <template v-slot:item.teacher="{ item }">
                      <span v-if="item.teacher_id && teachers.length > 0">
                        {{
                          teachers.find(
                            (teacher) => teacher.id == item.teacher_id
                          ) != undefined
                            ? teachers.find(
                                (teacher) => teacher.id == item.teacher_id
                              ).name
                            : ""
                        }}
                      </span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <!-- <v-icon
                        @click="editRow(item)"
                        v-if="editSubjectsBtnVisible"
                        :title="$t('Subject Details')"
                        >mdi-eye</v-icon
                      > -->
                      <v-icon
                        v-if="deleteSubjectsBtnVisible"
                        @click="showDialog(item)"
                        :title="$t('Delete')"
                        >delete</v-icon
                      >
                     
                      <v-icon
                        class=""
                        @click="editSubject(item)"
                         v-if="editSubjectsBtnVisible"
                        :title="$t('Edit')"
                        >edit</v-icon
                      >
                    </template>
                  </v-data-table>
                  <div class="text-center pt-2">
                    <v-pagination
                      v-if="length > 0 && totalSubjects > 0"
                      v-model="page"
                      :length="length"
                      :circle="circle"
                      :page="page"
                      :total-visible="totalVisible"
                      next-icon="mdi-menu-right"
                      prev-icon="mdi-menu-left"
                    ></v-pagination>
                  </div>

                  <!-- Confirmation dialog delete subject -->
                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="300"
                    class="dialog_confirm"
                  >
                    <v-card>
                      <v-card-title class="headline">{{
                        $t("Confirmation Message")
                      }}</v-card-title>

                      <v-card-text>
                        {{
                          $t(
                            "Are you sure you want to permanently remove subject name"
                          )
                        }}
                        {{ deletedItem.name }}
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          class="closebuttonvutify footer-btn bg-linkedin"
                          @click="dialog = false"
                          id="noBtn"
                          >{{ $t("No") }}</v-btn
                        >
                        <v-btn
                          @click="deleteItem(deletedItem)"
                          class="approvebuttonvutify footer-btn bg-linkedin"
                          id="yesBtn"
                          >{{ $t("Yes") }}</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Books -->
        <v-tab-item v-can="'list-academic-book-subjects'">
          <v-card flat>
            <v-card-text>
              <DataTableComponent :table="tableBooks"></DataTableComponent>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Addmission Exams -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div class="card height-auto v-application v-application--is-ltr">
                <div class="card-body">
                  <v-data-table
                    :headers="tableExam.headers"
                    :items="tableExam.items"
                    :items-per-page="15"
                    class="elevation-1 level1"
                    :loading="loading"
                    item-key="id"
                    loading-text="Loading... Please wait"
                    hide-default-footer
                  >
                    <template v-slot:top>
                      <v-row class="headerTable-div">
                        <v-col md="6" sm="12">
                          <h3 class="custom-header main-color">
                            {{ $t("Admission Exams") }}
                          </h3>
                        </v-col>

                        <v-col md="6" sm="12" class="text-right">
                          <!-- ==================================================filters================================================== -->

                          <v-icon
                            v-if="tableExam.items.length == 0"
                            :title="$t('Add New')"
                            @click="addExam(null)"
                            class="main-color"
                            large
                            >add_circle</v-icon
                          >
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon @click="addExam(item)" :title="$t('Edit')"
                        >edit</v-icon
                      >
                      <v-icon
                        @click="openDeleteExam(item.id)"
                        :title="$t('Delete')"
                        >delete</v-icon
                      >
                      <router-link
                        :to="
                          '/applicantExamResults/' +
                          $route.params.grade_year_id +
                          '/' +
                          item.id
                        "
                        :title="$t('Applicant Exam Result')"
                      >
                        <v-icon>assignment</v-icon>
                      </router-link>
                      <router-link
                        :to="'/viewOnlineExam/' + item.id"
                        :title="$t('Admission Exam')"
                      >
                        <img
                          src="../../assets/elearning-online-gray.svg"
                          alt
                          width="20"
                          height="20"
                        />
                      </router-link>
                      <v-icon
                        @click="sendAdmissionNotification(item.id)"
                        :title="$t('Send Admission Notification')"
                        >notification_important</v-icon
                      >
                    </template>
                  </v-data-table>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- add subject component  -->
    <addSubject
      :addDialog="addDialog"
      :grade_year_id="grade_year_id"
      :teachers="teachers"
      :editableItem="editableItem"
      @onCancelAddSubject="cancelAddSubject"
      @addNewSubject="addNewSubject"
    ></addSubject>
    <!-- v-if="addDialog" -->
    <!-- snackBar -->
    <div class="text-center ma-2">
      <v-snackbar
        v-model="snack"
        :color="color"
        :timeout="timeout"
        :top="true"
        :right="true"
      >
        {{ text }}
        <v-btn color="pink" text @click="snack = false">{{
          $t("Close")
        }}</v-btn>
      </v-snackbar>
    </div>

    <addEditExamComponent
      :editedItem="tableExam.editedItem"
      :AddEditdialog="ExamDialog"
      :formTitle="$i18n.t('Exam')"
      v-on:childToParent="onChildClickExam"
      :editedIndex="tableExam.editedIndex"
      :endPoints="tableExam.apiEndPoints"
    ></addEditExamComponent>

    <!-- Confirm Delete Admission Exam modal-->
    <v-dialog
      v-model="dialogdelete"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t(
            "Are you sure you want to permanently remove this exam? you won't be able to view the student answers"
          )
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="closeDeleteExamDia()"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="DeleteExam(examSelectid)"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end Confirm Delete Admission Exam modal-->

    
  </div>
</template>
<script>
import axios from "axios";
import DataTableComponent from "../../components/DataTableComponent";
import addSubject from "../../components/modals/addSubject";
import ACL from "../../acl";
import { validationMixin } from "../../mixins/validationMixin";

import addEditExamComponent from "../../components/modals/AddEditAdmissionExamComponent";
export default {
  name: "GradeYearsRelatedData",
  components: {
    DataTableComponent,

    addEditExamComponent,
    addSubject,
  },
  mixins: [validationMixin],
  data() {
    return {
      editableItem: {},
      dialogdelete: false,
      ExamDialog: false,
      assignSubjectsBtnVisible: ACL.checkPermission(
        "assign-subjects-in-grade-year"
      ),
      editSubjectsBtnVisible: ACL.checkPermission(
        "edit-subjects-in-grade-year"
      ),
      deleteSubjectsBtnVisible: ACL.checkPermission(
        "delete-assigned-subjects-in-grade-year"
      ),
      viewQualifierBtnVisible: ACL.checkPermission(
        "view-qualifier-subjects-in-grade-year"
      ),
      addQualifierBtnVisible: ACL.checkPermission(
        "add-qualifier-subjects-in-grade-year"
      ),
      editQualifierBtnVisible: ACL.checkPermission(
        "edit-qualifier-subjects-in-grade-year"
      ),
      tab: null,
      headers: [
        { text: this.$i18n.t("Name"), value: "name" },
        { text: this.$i18n.t("Type"), value: "subject_type_name" },
        { text: this.$i18n.t("Passing Mark"), value: "passing_mark" },
        { text: this.$i18n.t("Quarter"), value: "quarter_names" },
        {
          text: this.$i18n.t("Teacher"),
          value: "teacher",
          sortable: false,
          width: "15%",
        },
        { text: this.$i18n.t("Total Mark"), value: "total_mark" },
        {
          text: this.$i18n.t("Actions"),
          value: "actions",
          sortable: false,
          width: "12%",
        },
      ],
      subjects: [],
      teachers: [],
      pageName: "",

      grade_year_id: "",
      loading: true,
      totalSubjects: 0,
      options: {
        itemsPerPage: 15,
      },
      search: "",
      subjectsFilter: "",

      //pagination options
      page: 0,
      circle: true,
      length: 0,
      totalVisible: 0,
      // end pagination
      // menuActions
      actionMenus: [{ title: "delete", icon: "delete", method: "delete" }],

      // dialog
      dialog: false,
      addDialog: false,
      deletedItem: [],

      /* snackBar */
      snack: false,
      text: this.$i18n.t("Item Deleted Successfully"),
      color: "success",
      timeout: 2000,

      subjectIds: [],
      tableExam: {
        headers: [
          {
            text: this.$i18n.t("Name"),
            value: "name",
            sortable: true,
          },
          {
            text: this.$i18n.t("Mark"),
            value: "mark", // not sortable item
            sortable: false,
          },
          {
            text: this.$i18n.t("Date"),
            value: "exam_date_from", // not sortable item
            sortable: true,
          },

          { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
        ],
        title: this.$i18n.t("Exams"),
        apiEndPoints: {
          list: "classes",
          create:
            "admissionExam/" +
            this.$router.currentRoute.params.grade_year_id +
            "/store",
          edit: "admissionExam/update",
          delete: "admissionExam/destroy",
        },
        editedIndex: -1,
        items: [],
        editedItem: {
          name: "",
          mark: "",
          exam_date_from: "",
          exam_date_to: "",
          academic_year_id: "",
          grade_year_id: this.$router.currentRoute.params.grade_year_id,
        },
      },
      // Classes
      table: {
        modal: "Class",
        path: "gradeyears/" + Number(0),
        headers: [
          // {
          //   text: "ID",
          //   value: "code"
          // },
          {
            text: this.$i18n.t("Name"),
            value: "en.name",
          },
          {
            text: this.$i18n.t("Arabic Name"),
            value: "ar.name",
          },
          {
            text: this.$i18n.t("Class Capacity"),
            value: "students_count",
          },
          {
            text: this.$i18n.t("Grade"),
            value: "academic_grade",
            sortable: false,
          },
          {
            text: this.$i18n.t("Head Teacher"),
            value: "teacher",
            sortable: false,
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("Classes"),
        apiEndPoints: {
          list: "classes",
          create: "classes/store",
          edit: "classes/update",
          delete: "classes/destroy",
        },
        editedItem: {
          en: {
            name: "",
          },
          ar: {
            name: "",
          },
          name: "",
          semester: "",
          name_arr: [],
          code: "",
          students_count: "",
          academic_grade_id: "",
          teacher_id: "",
        },
        academic_grade_id: "",
        permissions: {
          list: "list-academic-class-subjects",
          add: "add-academic-class-subjects",
          edit: "edit-academic-class-subjects",
          delete: "delete-academic-class-subjects",
        },
      },
      tableBooks: {
        modal: "Book",
        path: "gradeyears/" + Number(2),
        headers: [
          {
            text: this.$i18n.t("Name"),
            value: "name",
          },
          {
            text: this.$i18n.t("Author"),
            value: "author",
          },
          {
            text: this.$i18n.t("Semester"),
            value: "semester_name",
            sortable: false,
          },
          {
            text: this.$i18n.t("Subject Assigned"),
            value: "subject",
            sortable: false,
          },

          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("Books"),
        apiEndPoints: {
          list: "books",
          create: "books/store",
          edit: "books/update",
          delete: "books/destroy",
        },
        editedItem: {
          name: "",
          name_arr: [],
          author: "",
          semester: "",
          academic_grade_id: "",
          subject_id: "",
        },
        academic_grade_id: "",
        permissions: {
          list: "list-academic-book-subjects",
          add: "add-academic-book-subjects",
          edit: "edit-academic-book-subjects",
          delete: "delete-academic-book-subjects",
        },
      },
      subject_id: "",
      examSelectid: "",
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.tab !== undefined) {
          let tabNumber = Number(this.$route.query.tab);
          this.tab = tabNumber;
        }
        // let tabNumber = Number(this.$router.currentRoute.params.tabNumber);
        if (this.tab == 1) {
          if (this.$route.query.page !== undefined) {
            this.getDataFromApi(Number(this.$route.query.page));
          }
        }
      },
    },
    options: {
      handler() {
        if (
          this.options.sortBy.length > 0 &&
          this.options.sortDesc.length > 0
        ) {
          this.getDataFromApi(this.page, this.search);
        }
      },
      deep: true,
    },
    search: {
      handler() {
        this.page = 1;
        this.getDataFromApi(1, this.search);
      },
      deep: true,
    },
    page: function () {
      if (this.tab == 1) {
        console.log("page watch hereee");
        let gradeId = this.$router.currentRoute.params.grade_year_id;
        this.$router.push(
          {
            path: "/gradeyears/" + gradeId + "?tab=1&page=" + this.page,
          },
          () => {}
        );
      }
    },
    // dialog: {
    //   handler() {
    //     this.autoFocusDialog();
    //   },
    // },
    // addDialog: {
    //   handler() {
    //     if (this.addDialog == true) {
    //       this.autoFocusDialog();
    //     }
    //   },
    // },
  },
  computed: {
    allSubjectsFilter() {
      return this.allSubjects.filter((subject) => {
        // if (subject.selected) this.subjectIds.push(subject.id);
        return subject.name
          .toLowerCase()
          .includes(this.subjectsFilter.toLowerCase());
      });
    },
  },
  methods: {
    editSubject(item){
      this.editableItem = item;
      console.log(item);
      this.addDialog = true;

    },
    addNewSubject(val) {
      this.addDialog = val;
      this.snack = true;
      this.color = "green";
      this.text = "The selected subjects has been added successfully";
      this.getDataFromApi(1);
    },
    cancelAddSubject(val) {
      console.log(val);
      this.addDialog = val;
      this.editableItem = {};
    },
    openDeleteExam(id) {
      this.examSelectid = id;
      this.dialogdelete = true;
    },
    closeDeleteExamDia() {
      this.examSelectid = "";
      this.dialogdelete = false;
    },
    DeleteExam(id) {
      axios
        .get(this.getApiUrl + "/admissionExam/destroy/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.closeDeleteExamDia();
          this.getExams();
          this.snack = true;
          this.color = "green";
          this.text = response.data.status.message;
        });
    },
    sendAdmissionNotification(id) {
      axios
        .get(this.getApiUrl + "/onlineexams/sendAdmissionNotification/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.snack = true;
          this.color = "green";
          this.text = response.data.status.message;
        });
    },
    onChildClickExam(value, item, index) {
      this.ExamDialog = value;
      if (item != null && index == -1) {
        this.tableExam.items.push(item);
        this.totalItems = this.totalItems + 1;
      } else if (index > -1)
        if (item != null) Object.assign(this.tableExam.items[index], item);
      if (value == false) {
        // when close
        this.tableExam.items = [];
        this.$nextTick(function () {
          // DOM is now updated
          this.getExams();
        });
      }
    },
    addExam(item = null) {
      this.ExamDialog = true;
      if (item != null) {
        this.tableExam.editedItem = item;
        this.tableExam.editedIndex = 1;
      }
      var _this = this;
      $(document).ready(function () {
        $("#exam_date_from").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var edate =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.tableExam.editedItem.exam_date_from = edate;
            } else {
              _this.tableExam.editedItem.exam_date_from = "";
            }
          },
        });

        $("#exam_date_to").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var edate =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.tableExam.editedItem.exam_date_to = edate;
            } else {
              _this.tableExam.editedItem.exam_date_to = "";
            }
          },
        });
      });
    },
    editRow(item) {
      this.addDialog = true;
      this.editableItem = item;
    },
    routeTab(tab) {
      let gradeId = this.$router.currentRoute.params.grade_year_id;
      // let pageNumber = this.$router.currentRoute.params.pageNumber;
      this.$router.push(
        {
          path: "/gradeyears/" + gradeId + "?tab=" + tab + "&page=1",
        },
        () => {}
      );
    },

    getDataFromApi(page) {
      let sort_type = "";
      let column = "";
      if (this.options.sortBy && this.options.sortBy.length > 0) {
        column = this.options.sortBy[0];
        sort_type = this.options.sortDesc[0] ? "DESC" : "ASC";
      }
      axios
        .get(
          this.getApiUrl +
            "/school/showSubjectsInGradeYear/" +
            this.grade_year_id +
            "?search=" +
            this.search +
            "&sort_type=" +
            sort_type +
            "&column=" +
            column +
            "&page=" +
            page,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.loading = false;
          this.addDialog = false;
          this.subjects = response.data.data.data.data;
          this.totalSubjects = response.data.data.data.total;
          this.page = response.data.data.data.current_page;
          this.length = response.data.data.data.last_page;
          if (this.length > 5) this.totalVisible = 5;
        });
    },

    showDialog(item) {
      this.dialog = true;
      this.deletedItem = item;
    },

    deleteItem(item) {
      let id = item.id;
      axios
        .post(
          this.getApiUrl +
            "/school/deleteAssignedSubjects/" +
            this.grade_year_id,
          { subject_id: id },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error) {
            this.snack = true;
            this.color = "error";
            this.text = response.data.status.message;
            this.dialog = false;
          } else {
            const index = this.subjects.indexOf(item);

            this.subjects.splice(index, 1);
            this.dialog = false;
            this.snack = true;
            this.color = "success";
            this.text = "The selected subject has been removed successfully";
            this.totalSubjects -= 1;
            const index2 = this.subjectIds.indexOf(item.id);
            this.subjectIds.splice(index2, 1);
            this.getDataFromApi("1");
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
      }
    },

    getExams() {
      axios
        .get(this.getApiUrl + "/getAllAdmissionExam/" + this.grade_year_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.tableExam.items = response.data.data;
          this.loading = false;
        });
    },
  },
  created() {
    this.grade_year_id = this.$router.currentRoute.params.grade_year_id;
    this.table.academic_grade_id = this.grade_year_id;
    this.tableBooks.academic_grade_id = this.grade_year_id;
    this.tableBooks.editedItem.academic_grade_id = this.grade_year_id;
    this.table.apiEndPoints.list = "classes/" + this.grade_year_id;
    this.tableBooks.apiEndPoints.list = "books/" + this.grade_year_id;
    let tabNumber = 0;
    let pageNumber = 1;
    if (
      this.$route.query.tab !== undefined &&
      this.$route.query.page !== undefined
    ) {
      pageNumber = Number(this.$route.query.page);
      tabNumber = Number(this.$route.query.tab);
      this.tab = tabNumber;
      this.page = pageNumber;
    }

    if (tabNumber == 1) {
      this.getDataFromApi(pageNumber);
    }

    if (!this.editSubjectsBtnVisible && !this.deleteSubjectsBtnVisible) {
      this.headers = this.headers.filter(function (row) {
        return row.value != "action";
      });
    }
    axios
      .get(
        this.getApiUrl + "/school/getAcademicGradeName/" + this.grade_year_id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      )
      .then((response) => {
        this.pageName = response.data.data.pageName;
      });

    axios
      .get(this.getApiUrl + "/users?role=Teacher", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.teachers = response.data.data;
      });
    this.getExams();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
.search-icon {
  margin-right: 30px;
}
.checkbox {
  padding: 0 !important;
  margin: 0 !important;
}

.table td {
  padding: 10px 10px 0;
}

.add-icon {
  margin-right: 20px;
}

// start search input
.search {
  display: inline-block;
  position: relative;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  margin: 0px 8px 7px 0px;
  padding: 7px 9px 0px 9px;
  border: 2px solid #bbbbbb;
  border-radius: 25px;
  transition: all 200ms ease;
  cursor: text;
  padding: 3px 5px;

  &:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 15px;
    right: -5px;
    top: 21px;
    background: #bbbbbb;
    border-radius: 3px;
    transform: rotate(-45deg);
    transition: all 200ms ease;
  }

  &.active,
  &:hover {
    width: 200px;
    margin-right: 0px;

    &:after {
      height: 0px;
    }
  }

  input {
    width: 100%;
    border: none;
    box-sizing: border-box;
    font-family: Helvetica;
    font-size: 15px;
    color: inherit;
    background: transparent;
    outline-width: 0px;
  }
}

.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}
.chart_icon {
  color: rgba(0, 0, 0, 0.54);
  font-size: 18px;
  padding-right: 2px !important;
  margin-bottom: -2px !important;
  cursor: pointer;
}

// end search input
</style>
