<template>
  <div>
    <!-- add subject dialog -->
    <div class="text-center">
      <v-dialog persistent v-model="addDialog" width="400">
        <v-card>
          <v-card-title class="mb-3">
            <span class="headline">{{ $t("Add Subject") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-col cols="12">
                    <label class="mb-1">{{ $t("Select Subjects") }}</label>
                    <v-select
                      :items="allSubjects"
                      v-model="selectedSubject"
                      :rules="[validationRules.required]"
                      
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                    ></v-select>
                    <!-- :disabled="editableItem && editableItem.id" -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <label class="mb-1">{{
                      $t("Head Teacher (Optional)")
                    }}</label>
                    <v-select
                      v-model="selectedTeacher"
                      :items="teachers"
                      item-text="name"
                      item-value="id"
                      
                      outlined
                      dense
                    ></v-select>
                    <!-- :disabled="editableItem && editableItem.id" -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <label class="mb-1">{{ $t("Qualifiers") }}</label>
                    <v-select
                      v-model="selectedQualifiers"
                      :items="qualifiers"
                      :rules="[checkMultiSelect]"
                      item-text="title"
                      item-value="id"
                      
                      outlined
                      dense
                      multiple
                      return-object
                    ></v-select>
                    <!-- :disabled="editableItem && editableItem.id" -->
                  </v-col>
                </v-row>

                <v-row
                  class="gray-background"
                  v-for="(qualifier, index) in selectedQualifiers"
                  :key="index"
                >
                  <v-col sm="4" class="my-1"
                    ><span>{{ qualifier.title }}</span></v-col
                  >

                  <v-col sm="6" class="px-0 my-1">
                    <div class="d-flex">
                      <label for="Mark d-inline-block " class="mx-2"
                        >{{ $t("Mark") }}:
                      </label>
                      <v-text-field
                        v-model="qualifier.mark"
                        :rules="[
                          validationRules.required,
                          validationRules.numeric,
                        ]"
                        
                        solo
                        dense
                      ></v-text-field>
                      <!-- :disabled="editableItem && editableItem.id" -->
                    </div>
                  </v-col>
                  <v-col
                    sm="2"
                    class="text-center my-1"
                    v-if="!editableItem && !editableItem.id"
                  >
                    <v-icon @click="deleteQualifier(qualifier.id)"
                      >delete</v-icon
                    >
                  </v-col>
                </v-row>
                <v-row
                  class="gray-background"
                  v-if="!isNaN(totalMark) && totalMark > 0"
                >
                  <v-col cols="12" class="py-0">
                    <hr class="divider mt-2 mb-2" />
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0">
                      <strong> {{ $t("Total Mark") }}: </strong>
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0">
                      {{ totalMark }}
                    </p>
                  </v-col>
                  <v-col>
                    <p class="mb-0 mt-4" cols="6">
                      <strong> {{ $t("Passing Mark") }}: </strong>
                    </p>
                  </v-col>
                  <v-col>
                    <p class="mb-0" cols="6">
                      <v-text-field
                        v-model="passingMark"
                        :rules="[
                          validationRules.required,
                          validationRules.numeric,
                          checkPassingMark,
                        ]"
                        
                        solo
                        dense
                      ></v-text-field>
                      <!-- :disabled="editableItem && editableItem.id" -->
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <label class="mb-1">{{ $t("Quarters") }}: </label>
                    <v-select
                      v-model="selectedQuarters"
                      item-text="name"
                      item-value="id"
                      :items="quarters"
                      :rules="[checkMultiSelect]"
                      
                      outlined
                      dense
                      multiple
                    >
                    </v-select>
                    <!-- :disabled="editableItem && editableItem.id" -->
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="modal-btn-cancel" @click="cancelSubjectAdd">{{
              $t("Cancel")
            }}</v-btn>
            <v-btn
              class="modal-btn-save"
              @click="addSubject"
              
              >{{ $t("Save") }}</v-btn
            >
            <!-- v-if="Object.keys(editableItem).length === 0" -->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  name: "addSubject",
  mixins: [validationMixin],
  props: {
    addDialog: {
      required: true,
      type: Boolean,
      type: false,
    },

    grade_year_id: {
      required: true,
      type: String || Number,
    },
    teachers: {
      required: true,
      type: Array,
      default: [],
    },
    editableItem: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      /* add subject new edit*/
      valid: false,
      quarters: [],
      allSubjects: [],
      subjectIds: [],
      selectedSubject: "",
      subjectsQualifiers: [],
      qualifiers: [],
      selectedQualifiers: [],
      selectedTeacher: "",
      selectedQuarters: [],
      passingMark: "",
    };
  },
  watch: {
    addDialog: {
      handler() {
        if (this.addDialog == true) {
          this.getQulifiers();
          this.getQuarters();
          this.selectedSubject = this.editableItem.id;
          if (this.editableItem && this.editableItem.id) {
            // this.getSubjectsMenuforEdit();
            this.getSubjectsMenu();
            this.selectedQualifiers = this.editableItem.qualifiers;
            this.selectedSubject = this.editableItem.id;
            this.selectedTeacher = this.editableItem.teacher_id;
            this.selectedQuarters = this.editableItem.quarter_ids;
            this.passingMark = this.editableItem.passing_mark;
          } else {
            this.getSubjectsMenu();
          }
        }
      },
    },
    
  },
  computed: {
    totalMark() {
      let total = 0;
      this.selectedQualifiers.forEach((item) => {
        if (item.mark) {
          total += Number(item.mark);
        }
      });

      return total;
    },
  },

  methods: {
    deleteQualifier(id) {
      this.selectedQualifiers = this.selectedQualifiers.filter(
        (item) => item.id != id
      );
    },
    getSubjectsMenu() {
      // subjects
      axios
        .get(
          this.getApiUrl +
            "/school/showAvailableSubjectsInGradeYear/" +
            this.grade_year_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.allSubjects = response.data.data.data;
          
        });
    },
    getSubjectsMenuforEdit() {
      // subjects
      axios
        .get(
          this.getApiUrl +
            "/school/showSubjectsInGradeYear/" +
            this.grade_year_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.allSubjects = response.data.data.data.data;
          
        });
    },
    cancelSubjectAdd() {
      // this.getSubjectsMenu();
      // this.addDialog = false;
      this.selectedQualifiers = [];
      this.selectedSubject = "";
      this.selectedTeacher = "";
      this.selectedQuarters = [];
      this.passingMark = "";
      this.resetValidation();
      this.$emit("onCancelAddSubject", false);
    },

    addSubject() {
      if (this.valid) {
        axios
          .post(
            this.getApiUrl + "/school/assignSubjects/" + this.grade_year_id,
            {
              id:this.editableItem.id ? this.editableItem.id : null,
              subject_id: this.selectedSubject,
              teacher_id: this.selectedTeacher,
              qualifiers: this.selectedQualifiers,
              quarter_ids: this.selectedQuarters,
              passing_mark: this.passingMark,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.code == 200) {
              this.selectedQualifiers = [];
              this.selectedSubject = "";
              this.selectedTeacher = "";
              this.selectedQuarters = [];
              this.passingMark = "";
              this.resetValidation();
              this.$emit("addNewSubject", false);
            }
          });
      } else {
        this.validate();
      }
    },

    getQulifiers() {
      axios
        .get(this.getApiUrl + "/qualifier?all_data", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.qualifiers = response.data.data;
        });
    },

    getQuarters() {
      axios
        .get(this.getApiUrl + "/academicPeriods/quarters", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.quarters = response.data.data;
        });
    },

    checkPassingMark() {
      return this.passingMark > this.totalMark
        ? "Pass mark shouldn’t be larger than total mark"
        : true;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.gray-background {
  background-color: #dddddd;
  border-radius: 5px;
  margin-right: 12px;
  margin-left: 12px;
}
</style>
